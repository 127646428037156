<template>
  <div>
    <!-- createOffer modal -->
    <b-modal ref="offerType" id="createOffer" centered no-close-on-backdrop>
      <div class="title centered">
        What are you offering for free to the local influencers?
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="image">
            <img src="@/assets/images/product.png" alt="" class="img-fluid" />
          </div>
          <a
            href="javascript:void(0);"
            @click="offerType('product')"
            class="btn btn-primary"
            >Free Product</a
          >
          <p>Ex: coffee, pizza, burger, bar etc..</p>
        </div>
        <div class="col-md-6">
          <div class="image">
            <img src="@/assets/images/service.png" alt="" class="img-fluid" />
          </div>
          <a
            href="javascript:void(0);"
            class="btn btn-primary"
            @click="offerType('service')"
            >Free Service</a
          >
          <p>Ex: gym, entry, haircut, spa, salon etc.</p>
        </div>
      </div>
    </b-modal>
    <!-- modal End-->

    <!-- Free Service modal -->
    <b-modal ref="freeService" id="freeService" centered no-close-on-backdrop>
      <div class="title">Offer type</div>
      <p class="subTitle">What free Porduct are you offering?</p>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(offers, change())">
          <base-input
            placeholder="Product Name*"
            type="text"
            rules="required"
            name="Product Name"
            v-model="newOffer.attributes.name"
          />
          <base-input
            placeholder="Product Value*"
            type="text"
            rules="required"
            name="Product value"
            v-model="newOffer.attributes.value"
          />
          <base-text-area
            label="Product Description (obtional)"
            v-model="newOffer.attributes.description"
            rules="required"
            name="Product description"
          ></base-text-area>
          <base-file-upload
            v-model="newOffer.image"
            label="Add an image for the offered product"
            rules="required"
          ></base-file-upload>
          <!-- <span v-if="!newOffer.image && isRequired" class="inValid-required"
            >Image is required</span
          > -->
          <base-radio-group
            label="Will the influencer need to call you for a reservation"
            name="needstocall"
            :options="needsToCall"
            v-model="newOffer.attributes.needsToCall"
            rules="required"
          ></base-radio-group>
          <base-radio-group
            label="How will the influencer get your offer?"
            name="offer"
            :options="offerDelivery"
            v-model="newOffer.attributes.deliveryType"
            rules="required"
          ></base-radio-group>
          <base-simple-suggest
            v-model="newOffer.attributes.category"
            rules="required"
            placeholder="Category"
            name="Category"
            :list="categories"
            text-attribute="name"
            value-attribute="id"
          ></base-simple-suggest>
          <p>Where Will this offer be a availiable?</p>
          <base-simple-suggest
            v-model="newOffer.attributes.location"
            rules="required"
            placeholder="Location"
            name="Location"
            :list="locations"
            text-attribute="city"
            value-attribute="id"
          ></base-simple-suggest>

          <!-- <span v-if="!newOffer.attributes.location && isRequired" class="inValid-required"
						>Location is required</span
					> -->
          <p>What do you want the influencer to include in the story?</p>
          <base-input
            placeholder="@our instagram"
            type="text"
            v-model="newOffer.attributes.storiesAccount"
            rules="required"
            name="our instagram"
          />
          <base-checkbox-group
            name="story"
            :options="storiesTypes"
            v-model="newOffer.attributes.storiesTypes"
            rules="required"
          ></base-checkbox-group>
          <base-input
            placeholder="Other instruction for the local influencerz"
            type="text"
            v-model="newOffer.attributes.storiesInstructions"
            rules="required"
            name="Instruction"
          />
          <base-radio-group
            label="Can influencers accept the product immediately?"
            name="product"
            :options="availability"
            v-model="newOffer.attributes.availability"
            rules="required"
          ></base-radio-group>
          <button type="submit" class="btn btn-primary">continue</button>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal End-->

    <!-- Task modal -->
    <b-modal ref="task" id="task" centered no-close-on-backdrop>
      <div class="title">Task</div>
      <p class="subTitle">
        How many influencers would you like to receive your free
        service/product.
      </p>
      <base-touch-spin
        sign=""
        v-model="newOffer.attributes.total"
        :price="1"
        text=" Influencers"
      />
      <p class="subTitle">What do you want the influencers to crate?</p>
      <div class="touch-spin">
        <base-touch-spin
          v-model="newOffer.attributes.storiesTotal"
          sign=""
          :price="1"
          text=" Instagram story"
        />
        <base-touch-spin
          v-model="newOffer.attributes.postsTotal"
          sign=""
          :price="3"
          text=" Instagram posts"
        />
      </div>
      <div class="title">Target</div>
      <p class="subTitle">
        Choose the minimum number of followers for a local influencer
      </p>
      <p class="number">{{ offerReach }}</p>
      <h3>Local influencers available for you</h3>
      <p class="green">Advanced filters</p>
      <div class="d-flex align-items-center justify-content-between mb-15">
        <p class="heading m-0">Followers</p>
        <span class="range">{{ newOffer.attributes.followers }}</span>
      </div>
      <!-- Rang slider -->
      <base-slider
        :min="1"
        :max="2000"
        v-model="newOffer.attributes.followers"
        @change="getEstimate"
      />
      <button
        type="button"
        class="btn btn-primary"
        :class="processing ? 'processing' : ''"
        @click="onFinish"
      >
        {{ btnText }}
      </button>
    </b-modal>
    <!-- modal End-->

    <!-- thankYou Modal -->

    <thank-you-modal
      :modal-show="showThankYou"
      :title="thankYouMessage"
    ></thank-you-modal>
    <!-- end -->
    <!-- filters modal -->
    <b-modal ref="filters" id="filters" centered>
      <div class="title">Filters</div>
      <h3>43.334<span>Local influencers available for you</span></h3>
      <div class="row">
        <div class="col-md-6">
          <base-select :options="gender" initialValue="Gender" />
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center justify-content-between">
            <p class="heading m-0">Age</p>
            <span class="range">{{ priceStart }} - {{ priceEnd }}</span>
          </div>
          <!-- MultiRang slider -->
          <base-range-slider
            :min="1"
            :max="80"
            :start="priceStart"
            :end="priceEnd"
            @rangeValue="selectedRangeValue"
          />
        </div>
      </div>
      <base-select :options="gender" initialValue="Gender" />
      <button
        type="button"
        class="btn btn-primary"
        @click="hideModal('filters')"
      >
        Show Results
      </button>
    </b-modal>
    <!-- modal End-->
  </div>
</template>

<script>
import { CREATE_OFFER } from "@/graphql/brand/mutations";
import { OFFER_REACH } from "@/graphql/brand/query";
import { mapGetters } from "vuex";
import { MESSAGES } from "@/_helpers/notifications";
let now = new Date();
let timestamp = now.getTime();
export default {
  data() {
    return {
      showThankYou: false,
      thankYouMessage: MESSAGES.OFFER_CREATED,
      locationSearch: null,
      categorySearch: null,
      isRequired: false,
      btnText: "Finish",
      offerReach: 500,
      processing: false,
      priceStart: 16,
      priceEnd: 65,
      gender: [
        { value: null, text: "Gender" },
        { value: "a", text: "Male" },
        { value: "b", text: "Female" },
      ],
      needsToCall: [
        { text: "No, don't call", value: false },
        { text: "Yes, call first", value: true },
      ],
      offerDelivery: [
        { text: "Home delivery to the influencer", value: "send" },
        { text: "Personal pick-up from our location", value: "pickup" },
      ],
      storiesTypes: [
        { text: "Our atmosphere", value: "atmosphere" },
        { text: "Our product", value: "product" },
      ],
      availability: [
        { text: "Yes, of corse", value: 0 },
        {
          text: "No, I will chech an approve personally every influencer",
          value: 1,
        },
      ],
      newOffer: {
        type: null,
        mediaType: 1,
        attributes: {
          name: null,
          value: null,
          description: null,
          location: null,
          category: null,
          total: 1,
          expirationDate: timestamp.toString(),
          followers: 500,
          availability: 0,
          needsToCall: false,
          deliveryType: "send",
          deliveryLocations: null,
          storiesTotal: 1,
          storiesAccount: null,
          storiesTypes: [],
          storiesInstructions: null,
          postsTotal: 3,
        },
        image: null,
      },
    };
  },
  components: {
    ThankYouModal: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/common/ThankYouModal.vue"
      ),
  },
  computed: {
    ...mapGetters({
      mediaAccounts: "mediaAccounts/list",
      locations: "locations",
      categories: "categories",
    }),
  },
  mounted() {
    this.getEstimate();
  },
  //   apollo: {
  //     offerReach: {
  //       query: OFFER_REACH,
  //       variables() {
  //         return {
  //           followers: this.newOffer.attributes.followers,
  //         };
  //       },
  //       error(e) {
  //         this.handleError(e);
  //       },
  //     },
  //   },
  methods: {
    change() {
      this.isRequired = true;
    },
    offerType(type) {
      this.newOffer.type = type;
      this.$refs["offerType"].hide();
      this.$refs["freeService"].show();
    },
    offers() {
      this.$refs["freeService"].hide();
      this.$refs["task"].show();
    },
    hideModal(data) {
      this.$refs[data].hide();
    },
    selectedRangeValue(start, end) {
      this.priceStart = start;
      this.priceEnd = end;
    },
    onFinish() {
      this.createOffer();
    },
    async getEstimate() {
      this.$apollo
        .query({
          query: OFFER_REACH,
          variables: {
            followers: this.newOffer.attributes.followers,
          },
        })
        .then((data) => {
          if (data) {
            this.offerReach = data.data.offerReach;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    async createOffer() {
      this.processing = true;
      this.btnText = "Processing...";
      this.newOffer.attributes.total = Number(this.newOffer.attributes.total);
      this.newOffer.attributes.storiesTotal = Number(
        this.newOffer.attributes.storiesTotal
      );
      this.newOffer.attributes.postsTotal = Number(
        this.newOffer.attributes.postsTotal
      );
      this.newOffer.mediaType = this.mediaAccounts[0].value;
      await this.$apollo
        .mutate({
          mutation: CREATE_OFFER,
          variables: this.newOffer,
        })
        .then((data) => {
          if (data) {
            if (data.data.createOffer.id) {
              this.processing = false;
              this.btnText = "Finish";
              this.$refs["task"].hide();
              this.showThankYou = true;
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
          this.btnText = "Finish";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-content {
    padding: rem(48px);
    .modal-body {
      .title {
        &.centered {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          max-width: 285px;
          margin-bottom: rem(36px) !important;
          @media screen and (max-width: 575px) {
            max-width: 240px;
          }
          @media screen and (max-width: 575px) {
            max-width: 220px;
          }
        }
      }
      .image {
        margin-bottom: rem(32px);
      }
      p {
        font-size: rem(14px);
        text-align: center;
        margin-top: rem(16px);
        color: var(--textPrimary);
      }
      @media screen and (max-width: 767px) {
        .row > div {
          text-align: center;
        }
      }
    }
  }
  &#freeService {
    .modal-content {
      .modal-body {
        p {
          font-size: rem(16px);
          text-align: left;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .checkbox {
          display: flex;
          justify-content: space-between;
        }

        .btn.btn-primary {
          width: 100%;
          margin-top: rem(24px);
        }
        ::v-deep {
          .v-select {
            .v-select-toggle {
              border-radius: 8px;
              font-size: rem(14px);
              color: var(--textPrimary);
              font-weight: 400;
              border: 1px solid #ccd4e0 !important;
              > div:first-child {
                padding-left: rem(32px);
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.152' height='23.939' viewBox='0 0 19.152 23.939'%3E%3Cpath id='Path_889' data-name='Path 889' d='M9.576,0A9.577,9.577,0,0,0,0,9.577C0,18.195,7.661,23.94,9.576,23.94s9.576-5.746,9.576-14.362A9.577,9.577,0,0,0,9.576,0Zm0,13.407a3.83,3.83,0,1,1,3.83-3.83A3.831,3.831,0,0,1,9.576,13.408Z' transform='translate(0 -0.001)' fill='%239637f1'/%3E%3C/svg%3E%0A");
                  background-repeat: no-repeat;
                  width: 20px;
                  height: 24px;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    ::v-deep {
      .v-select {
        .v-select-toggle {
          border-radius: 8px;
          font-size: rem(14px);
          color: var(--textPrimary);
          font-weight: 400;
          border: 1px solid #ccd4e0 !important;
          > div:first-child {
            padding-left: rem(32px);
            position: relative;
            &::before {
              content: "";
              position: absolute;
              background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.152' height='23.939' viewBox='0 0 19.152 23.939'%3E%3Cpath id='Path_889' data-name='Path 889' d='M9.576,0A9.577,9.577,0,0,0,0,9.577C0,18.195,7.661,23.94,9.576,23.94s9.576-5.746,9.576-14.362A9.577,9.577,0,0,0,9.576,0Zm0,13.407a3.83,3.83,0,1,1,3.83-3.83A3.831,3.831,0,0,1,9.576,13.408Z' transform='translate(0 -0.001)' fill='%239637f1'/%3E%3C/svg%3E%0A");
              background-repeat: no-repeat;
              width: 20px;
              height: 24px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  &#task {
    .modal-content {
      .modal-body {
        p {
          font-size: rem(16px);
          text-align: left;
          font-weight: 500;
          margin-bottom: 10px;
          &.number {
            font-size: rem(35px);
            font-weight: 700;
            line-height: 1;
            margin-bottom: rem(17px);
          }
          &.heading {
            font-weight: 400;
          }
          &.green {
            color: #24d694;
            font-weight: 500;
          }
        }
        h3 {
          font-size: rem(25px);
          font-weight: 700;
          font-family: $primary-font;
        }
        .touch-spin {
          display: flex;
          align-items: center;
          margin: rem(20px) 0;
          @media screen and (max-width: 500px) {
            flex-wrap: wrap;
            // align-items: flex-start;
            flex-direction: column;
          }
          div {
            margin: 0;
            &:first-child {
              margin-right: rem(28px);
              @media screen and (max-width: 500px) {
                margin: 0 0 rem(16px) 0;
              }
            }
          }
        }
        .btn.btn-primary {
          width: 100%;
          margin-top: rem(24px);
        }
      }
    }
  }

  &#filters {
    h3 {
      font-size: rem(35px);
      font-weight: 700;
      display: flex;
      align-items: center;
      span {
        font-size: rem(25px);
        margin-left: 8px;
      }
    }
    .btn.btn-primary {
      width: 100%;
      margin-top: rem(24px);
    }
  }
}
::v-deep {
  .v-select {
    .v-select-toggle {
      border-radius: 8px;
      font-size: rem(14px);
      color: var(--textPrimary);
      font-weight: 400;
      border: 1px solid #ccd4e0 !important;
    }
  }
}
</style>
